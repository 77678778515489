@tailwind base;
/* Your own custom base styles */
/* Start purging... */
@tailwind components;
/* Stop purging. */
/* Your own custom component styles */
/* Start purging... */
@tailwind utilities;
/* Stop purging. */
/* Your own custom utilities */
@import url('https://fonts.googleapis.com/css2?family=Spectral:wght@200&display=swap');

.fusion{
  background-color: transparent;
  stroke: black;
  stroke-width: 2px;
  stroke-miterlimit: 5;
  width: 34px;
  height: 34px;
  fill: none;
}
